import Link from 'next/link';
import * as React from 'react';
import { InstagramIcon, TwitterIcon } from '../shared/Icons';

export const Footer: React.FunctionComponent = () => (
  <footer className="  text-md bg-black  relative overflow-hidden pb2">
    <div className=" py-2 md:w-50-ns w-full w-100 flex md:gap-4 gap-3 justify-center">
      <Link href="/about" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1 ">About</span>
      </Link>
      <div className="h-[1px] bg-[#d44727] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/events" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Events</span>
      </Link>
      <div className="h-[1px] bg-[#f1b8a7] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/terms" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Terms</span>
      </Link>
      <div className="h-[1px] bg-[#4d748b] md:w-[30px] w-[10px] my-auto"></div>
      <Link href="/privacy" legacyBehavior>
        <span className="white no-underline fw6  b db pv1 mv1">Privacy Policy</span>
      </Link>
    </div>
    <div className=" border-t-2 text-xs text- font-semibold text-white p-2 h-fit flex justify-between">
      <div className="pt-2">© 2023 TBA. All Rights Reserved.</div>
      <div className="flex mx-2">
        {/* <span className="mx-4">Terms & Privacy - FAQ - Contact</span> */}
        <div className="flex gap-2">
          <a href="https://www.instagram.com/whatstba/" target="_blank" rel="noreferrer">
            <InstagramIcon />
          </a>
          <a href="https://twitter.com/WhatsTBA" target="_blank" rel="noreferrer">
            <TwitterIcon />
          </a>
        </div>
      </div>
    </div>
    {/* <form className="justify-center flex-nowrap items-center ">
			<label className=" dib">
				<input
					style={{ lineHeight: '32px' }}
					className="overflow-visible bn ph2 pv1 f5 fw6 black"
					placeholder="Email"
				/>
			</label>
			<button
				type="submit"
				className="bn bg-transparent white pl3 ml1 fw6 dim f4"
			>
				Subscribe
			</button>
		</form> */}
    {/* <ul className=" fr db pb2 ma0 pt2">
      <li className="fl tr mr2 list">
        <a className="fl dib dim no-underline white " href="/terms">
          Terms
        </a>
      </li>
      <li className="fl tr list">
        <a
          className="fl dim dib no-underline white "
          target="_blank"
          href="https://twitter.com/vimarethomas"
        >
          Privacy Policy
        </a>
      </li>
    </ul>
  */}
  </footer>
);
