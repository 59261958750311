import { FunctionComponent, useState, useEffect } from 'react';
import { Nav, Footer } from '.';
import Head from 'next/head';
import classnames from 'classnames';
import { SITE_NAME, SITE_TITLE, SITE_DESCRIPTION, SITE_IMAGE } from '@/constants/env';
import { createLogger } from '@/modules/logging/logger';
const logger = createLogger({
  fileLabel: `components/Layout/Layout`,
});

interface LayoutProps {
  noPadding?: boolean;
  data?: {
    title: string;
    header: string;
    description?: string;
    image?: string;
  };
  pageName?: string;
  children: React.ReactNode;
  customSite?: boolean;
}
export const Layout: FunctionComponent<LayoutProps> = ({ noPadding, children, data, pageName, customSite }) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [slidingMenuOpen, setSlidingMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
    }
  });

  useEffect(() => {
    if (document && slidingMenuOpen) {
      const header = document.getElementById('header');
      setHeaderHeight(header?.clientHeight || 0);
    }
  });
  const handleScroll = () => {
    setHeaderHeight(window?.pageYOffset);
  };

  return (
    <div className="min-h-screen h-auto  max-h-fit overflow-hidden">
      <Head>
        {/* <meta httpEquiv="Content-Security-Policy" content="default-src https:"/> */}

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1" />
        <meta property="og:type" content="website" />
        <meta name="insight-app-sec-validation" content="0c79e84c-4151-4140-ab79-130c685bddb3" />
        <meta property="og:site_name" content={data?.header || SITE_NAME} />
        <meta property="og:title" content={data?.title || SITE_TITLE} />
        <title>{data?.title || SITE_TITLE}</title>
        <meta property="og:description" content={data?.description || SITE_DESCRIPTION} />
        <meta property="og:image" content={data?.image || SITE_IMAGE} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={data?.header || SITE_NAME} />
        <meta name="twitter:title" content={data?.title || SITE_TITLE} />
        <meta name="twitter:description" content={SITE_DESCRIPTION} />
        <meta property="twitter:image" content={data?.image || SITE_IMAGE} />
        <meta name="format-detection" content="telephone=no, address=no, email=no" />
      </Head>

      {customSite ? (
        children
      ) : (
        <div className="flex flex-col h-full min-h-screen">
          <Nav
            headerHeight={headerHeight}
            setVisible={setSlidingMenuOpen}
            visible={slidingMenuOpen}
            noPadding={noPadding}
          />

          <div
            className={`bg-black h-full min-h-[88.5vh] leading-tight font-grotesk white ${classnames({
              'pa4-ns pa3': !noPadding,
            })} relative  `}>
            {/*language=PostCSS*/}
            <div
              className="db min-h-screen"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: 0.55,
                background: 'url(/static/line-background.svg) 50% no-repeat',
                backgroundSize: 'cover',
              }}
            />
            <div
              className={`${classnames({
                'pa4-ns pa3': !noPadding,
              })}`}></div>
            <main className="relative mt ">{children}</main>
          </div>
          <div className="mt-auto">
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};
